//==============================================================================
//==============================================================================
import * as React from 'react';
import { HtmlHeadInclude } from '@msdyn365-commerce/core-internal';
import { ILogrocketProps } from './logrocket.props.autogenerated';

//==============================================================================
//==============================================================================

// Default script source. This was moved to config since LogRocket changes domains very frequently.
// Newest at time of authoring, but blacklisted by RSM: 'https://cdn.logr-ingest.com/LogRocket.min.js'
const SCRIPT_SRC = 'https://cdn.intake-lr.com/LogRocket.min.js';

//==============================================================================
//==============================================================================
/**
 * LogRocket component
 * @extends {React.PureComponent<ILogRocketProps<{}>>}
 */
class LogRocket extends React.PureComponent<ILogrocketProps<{}>> {

    //----------------------------------------------------------
    //----------------------------------------------------------
    public render(): JSX.Element | null {

        // Make sure logging is enabled
        if (!this.props.config.isEnabled) {
            return null;
        }

        // Get script source
        const scriptSrc = this.props.config.url || SCRIPT_SRC;

        // Construct script contents
        const scriptContents = this.createInitScript() + this.createIdentifyScript();

        // Load the LogRocket script, then initialize LogRocket
        return (
            <HtmlHeadInclude>
                <script data-load-point='headEnd' src={scriptSrc} crossOrigin="anonymous" />
                <script data-load-point='headEnd' dangerouslySetInnerHTML={{ __html: scriptContents }} />
            </HtmlHeadInclude>
        );
    }

    //----------------------------------------------------------
    // Generate the initialization script
    //----------------------------------------------------------
    private createInitScript() {

        // Pull the init script from config to keep it user modifiable (default: the minified version of Init.md)
        // This controls data sanitization
        return `LogRocket.init("${this.props.config.key}", ${this.props.config.initScript || '{}'});`;
    }

    //----------------------------------------------------------
    // Add user identity information if the user is logged in
    //----------------------------------------------------------
    private createIdentifyScript() {
        const user = this.props.context.request.user;
        return user.customerAccountNumber ?
            `LogRocket.identify('${user.customerAccountNumber}', {name:'${user.name}', email:'${user.emailAddress}'});` :
            '';
    }
}

export default LogRocket;
